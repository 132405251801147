import React, {memo, useEffect, useContext, useState} from 'react';
import styles from './index.module.scss';
import {toggleScroll, useDelayUnmount} from "../../../helpers/utils";
import {createPortal} from "react-dom";
import {GlobalContext} from "../../../index";
import cx from "classnames";

const Loader = ({loading, className, isGlobal = true}: {loading?: boolean, className?: string, isGlobal?: boolean}) => {
  const {state: {isLoading}} = useContext<any>(GlobalContext);
  const [isMounted, setIsMounted] = useState(true);
  const shouldRenderChild = useDelayUnmount(isMounted, 300);
  useEffect(() => {
    setIsMounted(isLoading)
    if(isGlobal) toggleScroll(isLoading)
    return () => {
      if(isGlobal) toggleScroll(false)
    }
  }, [isLoading, isGlobal])

  const Component = () => (
    <div className={cx(styles.root, className, {
      [styles.active]: loading || (shouldRenderChild && isMounted),
      [styles.global]: isGlobal
    })}>
      <div className={styles.circle}>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  )

  if (isGlobal) return createPortal(<Component/>, document.getElementById("loader") as Element)
  else return <Component/>
}

export default memo(Loader)
