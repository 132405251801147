import star from "../assets/icons/star.svg";
import profile from "../assets/icons/profile.svg";
import speedometer from "../assets/icons/speedometer.svg";
import seat from "../assets/icons/seat.svg";
import car from "../assets/icons/car.svg";
import shield from "../assets/icons/shield.svg";
import wifi from "../assets/icons/wifi.svg";
import calendar from "../assets/icons/calendar.svg";
import price from "../assets/icons/price.svg";
import bulb from "../assets/icons/bulb.svg";
import check from "../assets/icons/check_circle.svg";
import circleCross from "../assets/icons/circle_cross.svg";
import { useEffect, useState } from "react";

export const parseIconUrl = (icon: string) => {
    if(icon === 'star') return star
    else if(icon === 'contacts') return profile
    else if(icon === 'speedometer') return speedometer
    else if(icon === 'seat') return seat
    else if(icon === 'car') return car
    else if(icon === 'security') return shield
    else if(icon === 'wifi') return wifi
    else if(icon === 'calendar') return calendar
    else if(icon === 'tag') return price
    else if(icon === 'lamp') return bulb
    else if(icon === 'check') return check
    else if(icon === 'cross') return circleCross
    else return star
}

export function debounce(func: Function, timeout: number){
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func(...args); }, timeout);
  };
}

export const scrollTo = (elementRef: any) => {
  if(!elementRef.current) return null
  const HEADER_HEIGHT = 96;
  //@ts-ignore
  const formOffset = window.pageYOffset + elementRef.current.getBoundingClientRect().top - HEADER_HEIGHT;
  window.scrollTo({ top: formOffset, behavior: 'smooth'});
}

export const toggleScroll = (disableScroll: boolean) => {
  document.body.style.overflow = disableScroll ? 'hidden' : 'unset';
}

//This function used to push custom event to Google Tag Manager. More info here: https://support.google.com/tagmanager/answer/7679219?hl=en
export const analyticsHandler = (eventName: string, params?: { [key: string]: any }) => {
  // @ts-ignore
  window.dataLayer?.push({'event': eventName, ...(params || {})})
}

export const capitalize = (string: string, splitSymbol?: string) => {
  return string
    .split(splitSymbol as string)
    .map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(splitSymbol)
}

export const useDelayUnmount = (isMounted: boolean, delayTime: number) => {
  const [ shouldRender, setShouldRender ] = useState(false);

  useEffect(() => {
    let timeoutId: any;
    if (isMounted && !shouldRender) setShouldRender(true);
    else if(!isMounted && shouldRender) {
      timeoutId = setTimeout(setShouldRender.bind(null, false), delayTime);
    }
    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);
  return shouldRender;
}

export const delayRequest = async (request?: any, delay: number = 300) => {
  const [moduleExports = null] = await Promise.all([
    request,
    new Promise(resolve => setTimeout(resolve, delay))
  ])
  return moduleExports
}


export const preloadImg = (url: string, type?: string) => {
  if(!url) return
  else return new Promise (resolve => {
    // @ts-ignore
    window.GLOBAL_PRELOAD_IMAGE_LOADED = false;
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = url;
    link.onload = () => {
      // @ts-ignore
      window.GLOBAL_PRELOAD_IMAGE_LOADED = true
    }
    link.onerror = () => {
      // @ts-ignore
      window.GLOBAL_PRELOAD_IMAGE_LOADED = true
    }
    if(type) link.type = type;
    document.head.appendChild(link);
    let interval = setInterval(() => {
      // @ts-ignore
      if(window.GLOBAL_PRELOAD_IMAGE_LOADED) {
        link.onload = null;
        link.onerror = null;
        clearInterval(interval)
        // @ts-ignore
        resolve()
      }
      }, 200)
  })
}

export const snakeCase = (string: string) => {
  return string.replaceAll(/([A-Z])/g, (props) => {
    return `_${props.toLowerCase()}`
  })
}

export const formatPageUrl = (string: string | undefined) => {
  if(!string) return ''
  return string.toLowerCase().replaceAll(/[ ]+/g, '-')
}

export const formatPhoneNumber = (event: any) => {
  let inputValue = event.target.value.replace(/\D/g, ''); // Remove all non-digit characters
  if (inputValue.length > 0 && inputValue.length <= 3) {
    inputValue = `(${inputValue.slice(0, 3)}`;
  } else if (inputValue.length > 3 && inputValue.length <= 6) {
    inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3)}`;
  } else if (inputValue.length > 5) {
    inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
  }
  return inputValue;
}

export const beautifyPageUrl = (string: string) => {
  if(!string) return ''
  return string
    .replace(/(.)/, (props) => props.toUpperCase())
    .replaceAll(/-(.)/g, (props) => ` ${props[1].toUpperCase()}`)
}

export const formatLinkUrl = (string: string) => {
  return string[0] === '/' ? string : `/${string}`
}

export const translateFormatPageUrl = (string: string) => {
  return [
    capitalize(string, '-').split('-').join(' '),
    capitalize(string, '-')
  ]
}

export const findFirstOptionByLetter = (options: OptionObject[], firstLetter: string) => {
  let subIndex: number | undefined
  const index = options.findIndex((currentValue) => {
    if('options' in currentValue) {
      return !!currentValue.options?.find(( {label}, j) => {
        const isStartsWithLetter = label.toLowerCase()[0] === firstLetter

        if(isStartsWithLetter) subIndex = j

        return isStartsWithLetter
      })
    } else {
      return currentValue.label.toLowerCase()[0] === firstLetter
    }
  })
  return subIndex !== undefined ? `-${index}-${subIndex}` : `-${index}`
}

export const handleCarImageError = ( {currentTarget}: any ) => {
  currentTarget.src = process.env.REACT_APP_NO_CAR_IMAGE as string
  currentTarget.onerror = null
}
