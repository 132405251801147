import React, {useReducer, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import './mixins.scss';
import Routes from './Routes';
import Loader from "./components/UI/Loader";
//TODO: uncomment web vitals when we'll be ready for wire up it with analytics
// import reportWebVitals from './reportWebVitals';

export const GlobalContext = React.createContext({});
const DEFAULT_STATE = {
  isLoading: false
};

const App = () => {
  const [state, dispatch] = useReducer((state: any, updateFields: { [key: string]: any }) => {
    return {...state, ...updateFields}
  }, DEFAULT_STATE);

  useEffect(() => {
    if(navigator.userAgent.indexOf('iPhone') > -1 ) {
      document
        .querySelector("[name=viewport]")
        ?.setAttribute("content","width=device-width, initial-scale=1, maximum-scale=1");
    }
  }, [])

  return (
    <React.StrictMode>
      <GlobalContext.Provider value={{state, dispatch}}>
        <Routes/>
        <Loader/>
      </GlobalContext.Provider>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
