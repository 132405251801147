import React, {lazy, Suspense} from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Loader from "./components/UI/Loader";

import {getPage} from "./services/api";
import {delayRequest, preloadImg} from "./helpers/utils";

//TODO: remove test images preload and change them with dynamically fetched ones
import homePage from './assets/images/hero/homePage.jpg';
import homePageMobile from './assets/images/hero/homePageMobile.jpg';
import carPage from './assets/images/hero/carsPage.webp';
import carPageMobile from './assets/images/hero/carsPageMobile.webp';
import sell from "./assets/images/hero/sell.webp";
import insurance from "./assets/images/hero/insurance.webp";

const CarsPage = lazy(() => import("./pages/CarsPage"));
const MakePage = lazy(() => import("./pages/MakePage"));
const ModelPage = lazy(() => import("./pages/ModelPage"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const MaintenancePage = lazy(() => import("./pages/MaintenancePage"));
const BestCarsPage = lazy(() => import("./pages/BestCarsPage"));
const BestTypePage = lazy(() => import("./pages/BestTypePage"));
const CheckAvailabilityPage = lazy(() => import("./pages/CheckAvailabilityPage"));
const ContactUsPage = lazy(() => import("./pages/ContactUsPage"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage"));
const TermsOfServicePage = lazy(() => import("./pages/TermsOfServicePage"));
const DisclaimerPage = lazy(() => import("./pages/DisclaimerPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const SellPage = lazy(() => import("./pages/SellPage"));
const InsurancePage = lazy(() => import("./pages/InsurancePage"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader/>}>
        <HomePage/>
      </Suspense>
    ),
    loader: async () => {
      await Promise.all([preloadImg(homePage), preloadImg(homePageMobile)])
      return await delayRequest()
    },
    name: 'Home'
  },
  {
    path: "/cars",
    hasErrorBoundary: true,
    errorElement: () => (<p>Text</p>),
    element: (
      <Suspense fallback={<Loader/>}>
        <CarsPage/>
      </Suspense>
    ),
    loader: async () => {
      try {
        await Promise.all([preloadImg(carPageMobile), preloadImg(carPage)])
        return null
      } catch (e) {
        return null
      }
    },
    name: 'Cars'
  },
  {
    path: "/cars/make/:companyName",
    element: (
      <Suspense fallback={<Loader/>}>
        <MakePage/>
      </Suspense>
    ),
    loader: async ({request: {url}}) => {
      const urlObj = new URL(url)
      try {
        const hasAccurateContent = urlObj.pathname === window.GLOBAL_PAGE_PATH;
        const res = hasAccurateContent ? window.GLOBAL_DYNAMIC_CONTENT : await delayRequest(getPage(`content${urlObj.pathname}`))

        await preloadImg(res.imageUrl)
        return res
      } catch (e) {
        return null
      }
    },
    name: 'companyName'
  },
  {
    path: "/cars/make/:companyName/:model/:year",
    loader: async ({request: {url}}) => {
      const urlObj = new URL(url)
      try {
        const hasAccurateContent = urlObj.pathname === window.GLOBAL_PAGE_PATH;
        const res = hasAccurateContent ? window.GLOBAL_DYNAMIC_CONTENT : await delayRequest(getPage(`content${urlObj.pathname}`));

        const photoComponent = res?.mainBodySection.find(({type}) => type === "PHOTO" || 'PHOTO_GALLERY')?.content
        const pageImage = photoComponent?.url || photoComponent?.items[0].url
        await preloadImg(pageImage)
        return {...res, pageImage, pathname: urlObj.pathname}
      } catch (e) {
        return null
      }
    },
    element: (
      <Suspense fallback={<Loader/>}>
        <ModelPage/>
      </Suspense>
    ),
    name: 'model'
  },
  {
    path: "/cars/:type",
    loader: async ({request: {url}}) => {
      const urlObj = new URL(url)
      try {
        const hasPreloadedContent = urlObj.pathname === window.GLOBAL_PAGE_PATH;
        const res = hasPreloadedContent ? window.GLOBAL_DYNAMIC_CONTENT : await delayRequest(getPage(`content${urlObj.pathname}`))

        await preloadImg(res.imageUrl)
        return res
      } catch (e) {
        return null
      }
    },
    element: (
      <Suspense fallback={<Loader/>}>
        <BestTypePage/>
      </Suspense>
    ),
    name: 'type'
  },
  {
    path: "/cars/:type/:articleName",
    loader: async ({request: {url}}) => {
      const urlObj = new URL(url);
      try {
        const hasPreloadedContent = urlObj.pathname === window.GLOBAL_PAGE_PATH;
        const res = hasPreloadedContent ? window.GLOBAL_DYNAMIC_CONTENT : await delayRequest(getPage(`content${urlObj.pathname}`));

        await preloadImg(res?.mainBodySection.find(({type}) => type === "PHOTO")?.content?.url)
        return res
      } catch (e) {
        return null
      }
    },
    element: (
      <Suspense fallback={<Loader/>}>
        <BestCarsPage/>
      </Suspense>
    ),
    name: 'articleName'
  },
  {
    path: "/best-cars/:type/:articleName",
    loader: async ({request: {url}}) => {
      const urlObj = new URL(url);
      try {
        const hasPreloadedContent = urlObj.pathname === window.GLOBAL_PAGE_PATH;
        const res = hasPreloadedContent ? window.GLOBAL_DYNAMIC_CONTENT : await delayRequest(getPage(`content${urlObj.pathname}`));

        await preloadImg(res?.mainBodySection.find(({type}) => type === "PHOTO")?.content?.url)
        return res
      } catch (e) {
        return null
      }
    },
    element: (
      <Suspense fallback={<Loader/>}>
        <BestCarsPage/>
      </Suspense>
    ),
    name: 'articleName'
  },
  {
    path: "/check_availability",
    loader: async () => await delayRequest(),
    element: (
      <Suspense fallback={<Loader/>}>
        <CheckAvailabilityPage/>
      </Suspense>
    ),
    name: 'Check Availability'
  },
  {
    path: "/contact_us",
    loader: async () => await delayRequest(),
    element: (
      <Suspense fallback={<Loader/>}>
        <ContactUsPage/>
      </Suspense>
    ),
    name: "Contact Us"
  },
  {
    path: "/privacy_policy",
    loader: async () => await delayRequest(),
    element: (
      <Suspense fallback={<Loader loading />}>
        <PrivacyPolicyPage/>
      </Suspense>
    ),
    name: "Privacy Policy"
  },
  {
    path: "/terms_of_service",
    loader: async () => await delayRequest(),
    element: (
      <Suspense fallback={<Loader/>}>
        <TermsOfServicePage/>
      </Suspense>
    ),
    name: "Terms of service"
  },
  {
    path: "/disclaimer",
    loader: async () => await delayRequest(),
    element: (
      <Suspense fallback={<Loader/>}>
        <DisclaimerPage/>
      </Suspense>
    ),
    name: "Terms of service"
  },
  {
    path: "/sell",
    loader: async () => {
      await preloadImg(sell)
      return await delayRequest()
    },
    element: (
      <Suspense fallback={<Loader/>}>
        <SellPage />
      </Suspense>
    ),
    name: "Sell car"
  },
  {
    path: "/insurance",
    loader: async () => {
      await preloadImg(insurance)
      return await delayRequest()
    },
    element: (
      <Suspense fallback={<Loader/>}>
        <InsurancePage />
      </Suspense>
    ),
    name: "Insurance"
  },
  {
    path: "*",
    loader: async () => {
      await preloadImg(insurance)
      return await delayRequest()
    },
    element: (
      <Suspense fallback={<Loader />}>
        <NotFoundPage />
      </Suspense>
    ),
    name: "404"
  },
  {
    path: "/maintenance",
    loader: async () => await delayRequest(),
    element: (
      <Suspense fallback={<Loader />} >
        <MaintenancePage />
      </Suspense>
    ),
    name: "Maintenance"
  },
]);

export const APP_ROUTES = router.routes.map(({path, name}) => ({name, path}))
const Routes = () => <RouterProvider router={router} />

export default React.memo(Routes)
