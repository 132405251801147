import ky from 'ky';

interface fetchDealersProps {
  make: string,
  model: string,
  year: string,
  postal_code?: string
}

interface contactDealerForm extends fetchDealersProps {
  first_name: string,
  last_name: string,
  phone: string,
  email: string,
  reservation_id: string
}

const api = ky.create({prefixUrl: process.env.REACT_APP_API_DOMAIN});

export const getPage = async (url: string): Promise<any> => {
  const res = await api.get(url+'/content.json')
  return res.json();
}

export const fetchDealers = async (data: fetchDealersProps): Promise<{
  nearby_dealers: {
    reservation_id: string,
    name: string,
    distance: string,
  }[],
  postal_code: string,
}> => {
  const res = await ky.post(`${process.env.REACT_APP_API_DOMAIN}api/lead/anonymous/find_nearby_dealers`, {
    json: {...data, customer_ip_address: "00.000.000.000"},
    headers: {
      "Api-Version": "2"
    }
  })
  return res.json()
}

export const submitContactDealersForm = async (data: contactDealerForm) => {
  const res = await ky.post(`${process.env.REACT_APP_API_DOMAIN}api/lead/anonymous/submit`, {
    json: {...data, customer_ip_address: "00.000.000.000"}
  })
  return res.json()
}
